import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'No creators to display',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `Order #3(global)#3
  #4(#1(creator)#1 #2(marketing)#2)#4
  services #5(in just a click!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Try "Fashion Marketing"',
  [InfluKeys.search.option2]: 'Try "Video Game Reviews"',
  [InfluKeys.search.option3]: 'Try "Product Reviews"',
  [InfluKeys.search.option4]: 'Try "Food Reviews"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Join as a Creator',

  // Mobile
  [InfluKeys.M.title]: 'Global influencer marketplace platform',
  [InfluKeys.M.search]: 'Search creators',
  [InfluKeys.M.content_box.request_creators.title]: 'Request Creators',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'Get a tailored list of global creators for your campaign.',

  // Card
  [InfluKeys.Card.my_card]: 'My Card',
  [InfluKeys.Card.view_package]: 'View Package',
  [InfluKeys.Card.creator_profile]: 'Creator Profile',
  [InfluKeys.Card.view_packages]: 'View Packages',
  [InfluKeys.Card.view_analytics]: 'View Analytics',
  [InfluKeys.Card.send_a_message]: 'Send a Message',
  [InfluKeys.Card.view_profile]: 'View Profile',
};
