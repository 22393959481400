import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '検索結果が見つかりませんでした。',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(世界中の)#1#2(クリエイター)#2
  #3(マーケティング)#3 #4(サービスを)#4
  #5(ワンクリックで注文!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '「ファッションマーケティング」を試してみる',
  [InfluKeys.search.option2]: '「ビデオゲームレビュー」を試してみる',
  [InfluKeys.search.option3]: '「製品レビュー」を試してみる',
  [InfluKeys.search.option4]: '「食べ物レビュー」を試してみる',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'クリエイターとして登録',

  // Mobile
  [InfluKeys.M.title]:
    'グローバルインフルエンサーマーケットプレイスプラットフォーム',
  [InfluKeys.M.search]: 'クリエーターを検索',
  [InfluKeys.M.content_box.request_creators.title]: 'クリエイターをリクエスト',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'キャンペーンのためにカスタマイズされたグローバルクリエイターリストを取得してください。',

  // Card
  [InfluKeys.Card.my_card]: 'マイカード',
  [InfluKeys.Card.view_package]: 'パッケージを見る',
  [InfluKeys.Card.creator_profile]: 'クリエータープロフィール',
  [InfluKeys.Card.view_packages]: 'パッケージを表示',
  [InfluKeys.Card.view_analytics]: 'データ分析を見る',
  [InfluKeys.Card.send_a_message]: 'メッセージを送信',
  [InfluKeys.Card.view_profile]: 'プロフィールを見る',
};
