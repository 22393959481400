import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '크리에이터가 없습니다',

  // Title
  [InfluKeys.title]: `#1(글로벌)#1 #2(크리에이터)#2
  #3(마케팅 서비스를)#3
  #4(원클릭)#4 #5(주문하세요!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '"패션 마케팅"을 검색해보세요',
  [InfluKeys.search.option2]: '"비디오 게임 리뷰"를 검색해보세요',
  [InfluKeys.search.option3]: '"제품 리뷰"를 검색해보세요',
  [InfluKeys.search.option4]: '"음식 리뷰"를 검색해보세요',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: '크리에이터로 등록하기',

  // Mobile
  [InfluKeys.M.title]: `글로벌 인플루언서 마켓플레이스 플랫폼`,
  [InfluKeys.M.search]: '크리에이터 검색',
  [InfluKeys.M.content_box.request_creators.title]: '크리에이터 요청하기',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    '진행하시는 캠페인에 딱 맞는 전세계 크리에이터 리스트를 받아보세요.',

  // Card
  [InfluKeys.Card.my_card]: '나의 카드',
  [InfluKeys.Card.view_package]: '패키지 보기',
  [InfluKeys.Card.creator_profile]: '크리에이터 프로필',
  [InfluKeys.Card.view_packages]: '패키지 보기',
  [InfluKeys.Card.view_analytics]: '데이터 분석 보기',
  [InfluKeys.Card.send_a_message]: '메세지 보내기',
  [InfluKeys.Card.view_profile]: '프로필 보기',
};
